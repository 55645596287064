const PhotoData = {
        wedding: [
            {
                id: 1,
                title: 'Romantic Sunset Ceremony',
                path: '/images/wedding/photo1.jpg',
                alt: 'Bride and groom sharing a kiss during a romantic sunset ceremony.',
            },
            {
                id: 2,
                title: 'Elegant Lace Wedding Dress',
                path: '/images/wedding/photo2.jpg',
                alt: 'Close-up of the bride\'s elegant lace wedding dress.',
            },
        {
            id: 3,
            title: 'Joyful Bridal Party',
            path: '/images/wedding/photo3.jpg',
            alt: 'Bridal party celebrating with joy and laughter.',
        },
        {
            id: 4,
            title: 'Rustic Outdoor Reception',
            path: '/images/wedding/photo4.jpg',
            alt: 'Guests enjoying a rustic outdoor wedding reception.',
        },
        {
            id: 5,
            title: 'Groomsmen Cheers',
            path: '/images/wedding/photo5.jpg',
            alt: 'Groomsmen raising their glasses in a toast.',
        },
        {
            id: 6,
            title: 'Bride\'s Elegant Bouquet',
            path: '/images/wedding/photo6.jpg',
            alt: 'Close-up of the bride\'s beautifully arranged bouquet.',
        },
        {
            id: 7,
            title: 'Candid Moments of Laughter',
            path: '/images/wedding/photo7.jpg',
            alt: 'Candid moments of laughter and joy during the ceremony.',
        },
        {
            id: 8,
            title: 'Exchanging Wedding Vows',
            path: '/images/wedding/photo8.jpg',
            alt: 'The couple exchanging heartfelt wedding vows.',
        },
        {
            id: 9,
            title: 'Family Blessings',
            path: '/images/wedding/photo9.jpg',
            alt: 'Family members offering blessings to the newlyweds.',
        },
        {
            id: 10,
            title: 'Beautiful Wedding Arch',
            path: '/images/wedding/photo10.jpg',
            alt: 'A stunning floral wedding arch framing the couple.',
        },
        {
            id: 11,
            title: 'Bridal Preparation',
            path: '/images/wedding/photo11.jpg',
            alt: 'Behind-the-scenes of the bride getting ready for the ceremony.',
        },
        {
            id: 12,
            title: 'Groomsmen Bonding',
            path: '/images/wedding/photo12.jpg',
            alt: 'Groomsmen sharing a lighthearted moment before the ceremony.',
        },
        {
            id: 13,
            title: 'Champagne Celebration',
            path: '/images/wedding/photo13.jpg',
            alt: 'Celebratory toast with champagne after the ceremony.',
        },
        {
            id: 14,
            title: 'Bride and Groom Portraits',
            path: '/images/wedding/photo14.jpg',
            alt: 'Stunning portraits of the bride and groom.',
        },
        {
            id: 15,
            title: 'Dance Floor Delight',
            path: '/images/wedding/photo15.jpg',
            alt: 'Guests having a blast on the dance floor.',
        },
        {
            id: 16,
            title: 'Vintage-Inspired Decor',
            path: '/images/wedding/photo16.jpeg',
            alt: 'Vintage-inspired wedding decor creating a charming atmosphere.',
        },
        {
            id: 17,
            title: 'Sentimental Wedding Rings',
            path: '/images/wedding/photo17.jpg',
            alt: 'Close-up of the bride and groom\'s sentimental wedding rings.',
        },
        {
            id: 18,
            title: 'Ceremony in Nature',
            path: '/images/wedding/photo18.jpg',
            alt: 'Outdoor ceremony surrounded by the beauty of nature.',
        },
        {
            id: 19,
            title: 'Bouquet Toss Excitement',
            path: '/images/wedding/photo19.jpg',
            alt: 'Excitement and laughter during the bouquet toss.',
        },
        {
            id: 20,
            title: 'Romantic Candlelit Reception',
            path: '/images/wedding/photo20.jpg',
            alt: 'Intimate and romantic candlelit wedding reception.',
        },
        {
            id: 21,
            title: 'Milestone Cake Cutting',
            path: '/images/wedding/photo21.jpg',
            alt: 'The couple cutting their beautiful wedding cake.',
        },
        {
            id: 22,
            title: 'Whimsical Outdoor Seating',
            path: '/images/wedding/photo22.jpg',
            alt: 'Whimsical outdoor seating arrangement for guests.',
        },
        {
            id: 23,
            title: 'Bridal Party Fun',
            path: '/images/wedding/photo23.jpg',
            alt: 'Playful moments with the lively bridal party.',
        },
        {
            id: 24,
            title: 'Grand Entrance',
            path: '/images/wedding/photo24.jpg',
            alt: 'Grand entrance of the bride and groom at the reception.',
        },
        {
            id: 25,
            title: 'Garden Wedding Vibes',
            path: '/images/wedding/photo25.jpg',
            alt: 'Capturing the romantic vibes of a garden wedding.',
        },
        {
            id: 26,
            title: 'Emotional Father-Daughter Dance',
            path: '/images/wedding/photo26.jpeg',
            alt: 'Heartfelt moment during the father-daughter dance.',
        },
        {
            id: 27,
            title: 'Spectacular Fireworks Display',
            path: '/images/wedding/photo27.jpg',
            alt: 'A spectacular fireworks display lighting up the night sky.',
        },
        {
            id: 28,
            title: 'Brides Stunning Veil',
            path: '/images/wedding/photo28.jpg',
            alt: 'Dramatic photo of the brides stunning flowing veil.',
        },
        {
            id: 29,
            title: 'Grooms Stylish Attire',
            path: '/images/wedding/photo29.jpg',
            alt: 'Close-up of the grooms stylish and dapper wedding attire.',
        },
        {
            id: 30,
            title: 'Lively Wedding Toasts',
            path: '/images/wedding/photo30.jpg',
            alt: 'Lively and heartwarming toasts from friends and family.',
        },
        {
            id: 31,
            title: 'Seaside Wedding Bliss',
            path: '/images/wedding/photo31.jpg',
            alt: 'Breathtaking views and seaside bliss during the wedding.',
        },
        {
            id: 32,
            title: 'Chic Wedding Tablescapes',
            path: '/images/wedding/photo32.jpg',
            alt: 'Chic and beautifully arranged wedding tablescapes.',
        },
        {
            id: 33,
            title: 'Intimate Vows Exchange',
            path: '/images/wedding/photo33.jpg',
            alt: 'Intimate moments during the exchange of wedding vows.',
        },
        {
            id: 34,
            title: 'Glowing Candle Decor',
            path: '/images/wedding/photo34.jpg',
            alt: 'Warm and glowing candle decor creating a romantic ambiance.',
        },
        {
            id: 35,
            title: 'Brides Radiant Smile',
            path: '/images/wedding/photo35.jpg',
            alt: 'Capturing the brides radiant and joyful smile.',
        },
        {
            id: 36,
            title: 'Outdoor Dance Celebration',
            path: '/images/wedding/photo36.jpg',
            alt: 'Celebration and dancing under the open sky.',
        },
        {
            id: 37,
            title: 'Stylish Wedding Shoes',
            path: '/images/wedding/photo37.jpg',
            alt: 'Stylish and unique wedding shoes worn by the bride.',
        },
        {
            id: 38,
            title: 'Soulful Live Music',
            path: '/images/wedding/photo38.jpg',
            alt: 'Soulful live music creating a magical atmosphere.',
        },
        {
            id: 39,
            title: 'Romantic First Dance',
            path: '/images/wedding/photo39.jpeg',
            alt: 'The couple sharing a romantic first dance.',
        },
        {
            id: 40,
            title: 'Sparkler Exit',
            path: '/images/wedding/photo40.jpg',
            alt: 'Magical sparkler exit as the couple leaves the venue.',
        },
        {
            id: 41,
            title: 'Vintage Car Getaway',
            path: '/images/wedding/photo41.jpg',
            alt: 'Bride and groom making a stylish getaway in a vintage car.',
        },
        {
            id: 42,
            title: 'Emotional Parent Dances',
            path: '/images/wedding/photo42.jpeg',
            alt: 'Heartfelt moments during the parent dances at the reception.',
        },
        {
            id: 43,
            title: 'Love-filled Wedding Reception',
            path: '/images/wedding/photo43.jpg',
            alt: 'Guests celebrating love and joy at the wedding reception.',
        },
    ],
    fashion: [
        {
          id: 1,
          title: 'Elegant Fashion Pose',
          path: '/images/fashion/photo1.jpg',
          alt: 'Elegant fashion model posing in a stylish outfit',
          description: 'An elegant fashion pose showcasing the latest trends in clothing and style.',
        },
        {
          id: 2,
          title: 'Urban Street Fashion',
          path: '/images/fashion/photo2.jpg',
          alt: 'Fashionable street style with an urban vibe',
          description: 'A trendy urban street fashion photo capturing the essence of modern style.',
        },
        {
          id: 3,
          title: 'Glamorous Evening Gown',
          path: '/images/fashion/photo3.jpg',
          alt: 'Model in a glamorous evening gown for a high-end fashion look',
          description: 'A glamorous fashion shot featuring an exquisite evening gown for a sophisticated look.',
        },
        {
          id: 4,
          title: 'Casual Chic Fashion',
          path: '/images/fashion/photo4.jpg',
          alt: 'Casual and chic fashion ensemble for a stylish day out',
          description: 'A casual yet chic fashion photo capturing a stylish ensemble suitable for various occasions.',
        },
        {
          id: 5,
          title: 'Bohemian Vibes',
          path: '/images/fashion/photo5.jpg',
          alt: 'Fashion model exuding bohemian vibes with a unique wardrobe',
          description: 'A bohemian-inspired fashion look with a unique wardrobe and free-spirited vibes.',
        },
        {
          id: 6,
          title: 'Runway Ready',
          path: '/images/fashion/photo6.jpg',
          alt: 'Fashion model ready to hit the runway with confidence',
          description: 'A runway-ready fashion photo capturing the confidence and poise of the model.',
        },
        {
          id: 7,
          title: 'Bold and Edgy Fashion',
          path: '/images/fashion/photo7.jpg',
          alt: 'Bold and edgy fashion statement with unique styling',
          description: 'An edgy fashion statement featuring bold and unique styling for a standout look.',
        },
        {
          id: 8,
          title: 'Chic Black and White',
          path: '/images/fashion/photo8.jpg',
          alt: 'Chic black and white fashion photography with timeless elegance',
          description: 'Timeless elegance captured in chic black and white fashion photography.',
        },
        {
          id: 9,
          title: 'Vintage Inspired Fashion',
          path: '/images/fashion/photo9.jpg',
          alt: 'Fashion model in vintage-inspired attire for a nostalgic look',
          description: 'A vintage-inspired fashion photo evoking a sense of nostalgia with classic attire.',
        },
        {
          id: 10,
          title: 'Street Style Confidence',
          path: '/images/fashion/photo10.jpg',
          alt: 'Confident street style fashion with a modern twist',
          description: 'Confident street style fashion with a modern twist, showcasing individuality and flair.',
        },
        {
          id: 11,
          title: 'High Fashion Editorial',
          path: '/images/fashion/photo11.jpg',
          alt: 'High fashion editorial photo with artistic and creative styling',
          description: 'An artistic and creative high fashion editorial photo with unique styling and composition.',
        },
        {
          id: 12,
          title: 'Sleek and Sophisticated',
          path: '/images/fashion/photo12.jpg',
          alt: 'Sleek and sophisticated fashion look for a polished appearance',
          description: 'A sleek and sophisticated fashion look for a polished and refined appearance.',
        },
        {
          id: 13,
          title: 'Effortlessly Chic',
          path: '/images/fashion/photo13.jpg',
          alt: 'Effortlessly chic fashion ensemble for a relaxed yet stylish vibe',
          description: 'An effortlessly chic fashion ensemble capturing a relaxed yet stylish vibe.',
        },
        {
          id: 14,
          title: 'Runway Glamour',
          path: '/images/fashion/photo14.jpg',
          alt: 'Glamorous fashion model on the runway showcasing designer attire',
          description: 'Runway glamour captured as a fashion model showcases designer attire with style and flair.',
        },
        {
          id: 15,
          title: 'Casual Elegance',
          path: '/images/fashion/photo15.jpg',
          alt: 'Casual elegance in fashion with a touch of sophistication',
          description: 'Casual elegance portrayed in fashion with a touch of sophistication for a versatile look.',
        },
        {
          id: 16,
          title: 'Modern Minimalism',
          path: '/images/fashion/photo16.jpg',
          alt: 'Modern minimalistic fashion look with clean lines and simplicity',
          description: 'A modern minimalistic fashion look characterized by clean lines and simplicity.',
        },
        {
          id: 17,
          title: 'Feminine Florals',
          path: '/images/fashion/photo17.jpg',
          alt: 'Fashion model in feminine florals for a soft and romantic look',
          description: 'Feminine florals in fashion, creating a soft and romantic look for a graceful appearance.',
        },
        {
          id: 18,
          title: 'Bold Patterns',
          path: '/images/fashion/photo18.jpg',
          alt: 'Fashion statement with bold patterns and striking visuals',
          description: 'Bold patterns make a fashion statement, creating a visually striking and impactful look.',
        },
        {
          id: 19,
          title: 'Cityscape Fashion',
          path: '/images/fashion/photo19.jpg',
          alt: 'Fashion model in cityscape setting with urban chic styling',
          description: 'Cityscape fashion featuring urban chic styling, blending sophistication with city vibes.',
        },
        {
          id: 20,
          title: 'Sunset Silhouette',
          path: '/images/fashion/photo20.jpg',
          alt: 'Fashion silhouette against a stunning sunset backdrop',
          description: 'Fashion silhouette capturing the beauty of a stunning sunset for a captivating photo.',
        },
        {
          id: 21,
          title: 'Youthful Energy',
          path: '/images/fashion/photo21.jpg',
          alt: 'Fashion model exuding youthful energy with vibrant styling',
          description: 'Youthful energy expressed through vibrant styling, creating a lively and dynamic fashion look.',
        },
        {
          id: 22,
          title: 'Classic Redefined',
          path: '/images/fashion/photo22.jpg',
          alt: 'Fashion model redefining classic style with a modern twist',
          description: 'Classic style redefined with a modern twist, showcasing the versatility of timeless fashion.',
        },
        {
          id: 23,
          title: 'Couture Elegance',
          path: '/images/fashion/photo23.jpg',
          alt: 'Couture elegance in fashion with luxurious fabrics and intricate details',
          description: 'Couture elegance portrayed through luxurious fabrics and intricate details for a sophisticated look.',
        },
        {
          id: 24,
          title: 'Dapper Gentlemen',
          path: '/images/fashion/photo24.jpg',
          alt: 'Dapper gentlemens fashion with tailored suits and refined style',
          description: 'Dapper gentlemens fashion featuring tailored suits and refined style for a polished appearance.',
        },
        {
          id: 25,
          title: 'Charming Eveningwear',
          path: '/images/fashion/photo25.jpg',
          alt: 'Charming eveningwear fashion for a memorable night out',
          description: 'Charming eveningwear fashion capturing the essence of a memorable night out with style and grace.',
        },
        {
          id: 26,
          title: 'Casual Weekend Vibes',
          path: '/images/fashion/photo26.jpg',
          alt: 'Casual weekend vibes in fashion with comfortable yet stylish attire',
          description: 'Casual weekend vibes portrayed in fashion with comfortable yet stylish attire for a relaxed look.',
        },
        {
          id: 27,
          title: 'Dynamic Fashion Movement',
          path: '/images/fashion/photo27.jpg',
          alt: 'Dynamic fashion movement with energy and flair',
          description: 'Dynamic fashion movement captured with energy and flair, showcasing the vibrancy of modern style.',
        },
    ],
    
  lifestyle: [
    {
      id: 1,
      title: 'Cafe Romance',
      path: '/images/lifestyle/photo1.jpg',
      alt: 'Couple enjoying a romantic moment at a cafe',
      description: 'A romantic photoshoot capturing the love and connection between a couple in a cozy cafe setting.',
    },
    {
      id: 2,
      title: 'Beach Sunset Bliss',
      path: '/images/lifestyle/photo2.jpg',
      alt: 'Couple embracing at the beach during a beautiful sunset',
      description: 'A blissful moment captured as a couple embraces against the backdrop of a stunning beach sunset.',
    },
    {
      id: 3,
      title: 'Temple Serenity',
      path: '/images/lifestyle/photo3.jpg',
      alt: 'Couple finding serenity and peace at a beautiful temple',
      description: 'A serene photoshoot capturing the couples peaceful moments at a beautiful and spiritual temple.',
    },
    {
      id: 4,
      title: 'Urban Adventure',
      path: '/images/lifestyle/photo4.jpg',
      alt: 'Couple exploring the city and enjoying an urban adventure',
      description: 'An adventurous photoshoot showcasing a couples exploration of the city and the excitement of urban life.',
    },
    {
      id: 5,
      title: 'Nature Retreat',
      path: '/images/lifestyle/photo5.jpg',
      alt: 'Couple surrounded by nature and enjoying a peaceful retreat',
      description: 'A tranquil photoshoot capturing a couples retreat into nature, surrounded by greenery and serenity.',
    },
    {
      id: 6,
      title: 'Rooftop Romance',
      path: '/images/lifestyle/photo6.jpg',
      alt: 'Romantic moments between a couple on a scenic rooftop',
      description: 'Romantic moments unfold as a couple enjoys each others company on a scenic rooftop with a breathtaking view.',
    },
    {
      id: 7,
      title: 'Countryside Love',
      path: '/images/lifestyle/photo7.jpg',
      alt: 'Couple expressing love in the peaceful countryside',
      description: 'Love blossoms in the peaceful countryside as a couple enjoys moments of togetherness surrounded by nature.',
    },
    {
      id: 8,
      title: 'Sunflower Fields Forever',
      path: '/images/lifestyle/photo8.jpg',
      alt: 'Couple surrounded by sunflower fields in a picturesque setting',
      description: 'A picturesque photoshoot capturing the joy of a couple surrounded by sunflower fields in full bloom.',
    },
    {
      id: 9,
      title: 'City Lights Date Night',
      path: '/images/lifestyle/photo9.jpg',
      alt: 'Couple enjoying a romantic date night amidst city lights',
      description: 'A romantic date night captured amidst the enchanting city lights, creating a magical atmosphere for the couple.',
    },
    {
      id: 10,
      title: 'Waterfront Connection',
      path: '/images/lifestyle/photo10.jpg',
      alt: 'Couple connecting by the waterfront with a scenic view',
      description: 'Connection and love flourish as a couple enjoys a serene moment by the waterfront with a scenic view.',
    },
    {
      id: 11,
      title: 'Starry Night Stroll',
      path: '/images/lifestyle/photo11.jpg',
      alt: 'Couple taking a romantic stroll under the starry night sky',
      description: 'Romance fills the air as a couple takes a leisurely stroll under the enchanting starry night sky.',
    },
    {
      id: 12,
      title: 'Mountain Escape',
      path: '/images/lifestyle/photo12.jpg',
      alt: 'Couple escaping to the mountains for a peaceful retreat',
      description: 'A peaceful retreat to the mountains allows a couple to connect and find solace in each others company.',
    },
    {
      id: 13,
      title: 'Chic Cafe Vibes',
      path: '/images/lifestyle/photo13.jpg',
      alt: 'Couple exuding chic vibes in a stylish cafe setting',
      description: 'Chic vibes and stylish moments unfold as a couple enjoys each others company in a trendy and fashionable cafe.',
    },
    {
      id: 14,
      title: 'Golden Hour Glow',
      path: '/images/lifestyle/photo14.jpg',
      alt: 'Couple bathed in the golden glow of the evening sun',
      description: 'A golden hour photoshoot capturing the warmth and glow as a couple basks in the evening sun.',
    },
    {
      id: 15,
      title: 'Cultural Connection',
      path: '/images/lifestyle/photo15.jpg',
      alt: 'Couple connecting with culture and heritage in a historic setting',
      description: 'A cultural photoshoot capturing a couples connection with history and heritage in a historic and scenic setting.',
    },
    {
      id: 16,
      title: 'Artistic Alleyways',
      path: '/images/lifestyle/photo16.jpg',
      alt: 'Couple exploring artistic alleyways with vibrant street art',
      description: 'Artistic exploration unfolds as a couple wanders through vibrant alleyways adorned with captivating street art.',
    },
    {
      id: 17,
      title: 'Lakeside Tranquility',
      path: '/images/lifestyle/photo17.jpg',
      alt: 'Couple enjoying tranquility by the lakeside with serene views',
      description: 'Tranquility surrounds a couple as they enjoy moments of peace by the lakeside, taking in the serene views.',
    },
    {
      id: 18,
      title: 'Bicycle Adventure',
      path: '/images/lifestyle/photo18.jpg',
      alt: 'Couple embarking on a charming bicycle adventure together',
      description: 'A charming photoshoot capturing the joy of a couple embarking on a bicycle adventure together, exploring scenic routes.',
    },
    {
      id: 19,
      title: 'Whimsical Woodland',
      path: '/images/lifestyle/photo19.jpg',
      alt: 'Couple immersed in the whimsical beauty of a woodland setting',
      description: 'Whimsical beauty unfolds as a couple explores the enchanting woodland surroundings, creating magical moments together.',
    },
    {
      id: 20,
      title: 'Rural Romance',
      path: '/images/lifestyle/photo20.jpg',
      alt: 'Couple embracing rural charm for a romantic photoshoot',
      description: 'Romantic moments unfold as a couple embraces the rural charm, creating a timeless and heartwarming photoshoot.',
    },
    {
      id: 21,
      title: 'Ethereal Elegance',
      path: '/images/lifestyle/photo21.jpg',
      alt: 'Couple radiating ethereal elegance in a dreamy setting',
      description: 'Ethereal elegance radiates as a couple poses in a dreamy setting, creating a magical and enchanting atmosphere.',
    },
    {
      id: 22,
      title: 'Sunlit Serenade',
      path: '/images/lifestyle/photo22.jpg',
      alt: 'Couple serenaded by sunlight in a picturesque location',
      description: 'Sunlight serenades a couple in a picturesque location, capturing the beauty of their love illuminated by the sun.',
    },
    {
      id: 23,
      title: 'Quaint Village Vibes',
      path: '/images/lifestyle/photo23.jpg',
      alt: 'Couple immersed in quaint village vibes for a charming photoshoot',
      description: 'Quaint village vibes create a charming backdrop as a couple immerses themselves in the beauty of a countryside photoshoot.',
    },
    {
      id: 24,
      title: 'Garden Delight',
      path: '/images/lifestyle/photo24.jpg',
      alt: 'Couple delighted by the beauty of a garden setting',
      description: 'Delightful moments unfold as a couple explores the beauty of a garden setting, surrounded by blooming flowers and lush greenery.',
    },
    {
      id: 25,
      title: 'Festival Fun',
      path: '/images/lifestyle/photo25.jpg',
      alt: 'Couple enjoying festive vibes and fun-filled moments',
      description: 'Festive fun and joyful moments unfold as a couple immerses themselves in the vibrant energy of a celebratory atmosphere.',
    },
    {
      id: 26,
      title: 'Sunset Romance',
      path: '/images/lifestyle/photo26.jpg',
      alt: 'Romantic moments between a couple in the warm glow of sunset',
      description: 'Romance fills the air as a couple shares intimate moments bathed in the warm and golden glow of a breathtaking sunset.',
    },
    {
      id: 27,
      title: 'Cultural Heritage',
      path: '/images/lifestyle/photo27.jpg',
      alt: 'Couple exploring cultural heritage in a historic setting',
      description: 'Exploration of cultural heritage unfolds as a couple immerses themselves in the rich history and architecture of a historic location.',
    },
    {
      id: 28,
      title: 'Vintage Vibes',
      path: '/images/lifestyle/photo28.jpg',
      alt: 'Couple embracing vintage vibes in a classic setting',
      description: 'Vintage vibes come to life as a couple embraces the classic charm of a bygone era, creating timeless and elegant moments.',
    },
    {
      id: 29,
      title: 'Cozy Fireplace Retreat',
      path: '/images/lifestyle/photo29.jpg',
      alt: 'Couple enjoying a cozy retreat by the fireplace',
      description: 'Cozy moments unfold as a couple enjoys the warmth of a fireplace retreat, creating a romantic and intimate atmosphere.',
    },
    {
      id: 30,
      title: 'Architectural Beauty',
      path: '/images/lifestyle/photo30.jpg',
      alt: 'Couple surrounded by architectural beauty and historic charm',
      description: 'Architectural beauty and historic charm provide a stunning backdrop as a couple poses in a photoshoot filled with elegance.',
    },
    {
      id: 31,
      title: 'Seaside Serenity',
      path: '/images/lifestyle/photo31.jpg',
      alt: 'Couple finding serenity by the seaside with tranquil views',
      description: 'Seaside serenity captured as a couple embraces the calm and tranquil beauty of the coastline in a serene photoshoot.',
    },
    ],
    realestate: [
        {
          id: 1,
          title: 'Luxury Living Room',
          path: '/images/realestate/photo1.jpg',
          alt: 'Elegant and luxurious living room in a modern home',
          description: 'Step into luxury with this elegant living room in a modern home, featuring sophisticated decor and a cozy atmosphere.',
        },
        {
          id: 2,
          title: 'Contemporary Kitchen Design',
          path: '/images/realestate/photo2.jpg',
          alt: 'Sleek and contemporary kitchen with state-of-the-art appliances',
          description: 'Experience the epitome of modern living in this sleek and contemporary kitchen, equipped with state-of-the-art appliances and stylish design.',
        },
        {
          id: 3,
          title: 'Master Bedroom Retreat',
          path: '/images/realestate/photo3.jpg',
          alt: 'Spacious master bedroom with a luxurious bed and stylish decor',
          description: 'Indulge in comfort and style with this spacious master bedroom retreat, featuring a luxurious bed and tastefully curated decor.',
        },
        {
          id: 4,
          title: 'Modern Bathroom Oasis',
          path: '/images/realestate/photo4.jpg',
          alt: 'Modern bathroom oasis with stylish fixtures and serene ambiance',
          description: 'Discover relaxation in this modern bathroom oasis, boasting stylish fixtures and a serene ambiance for a spa-like experience.',
        },
        {
          id: 5,
          title: 'Sunlit Dining Area',
          path: '/images/realestate/photo5.jpg',
          alt: 'Sunlit dining area with elegant furniture and scenic views',
          description: 'Enjoy meals in style with this sunlit dining area, featuring elegant furniture and scenic views that create a perfect dining experience.',
        },
        {
          id: 6,
          title: 'Cozy Reading Nook',
          path: '/images/realestate/photo6.jpg',
          alt: 'Cozy reading nook with comfortable seating and warm lighting',
          description: 'Embrace tranquility in this cozy reading nook, offering comfortable seating and warm lighting for a delightful reading experience.',
        },
        {
          id: 7,
          title: 'Private Home Office',
          path: '/images/realestate/photo7.jpg',
          alt: 'Private home office with modern furnishings and natural light',
          description: 'Boost productivity in this private home office, designed with modern furnishings and abundant natural light to inspire creativity.',
        },
        {
          id: 8,
          title: 'Rooftop Garden Retreat',
          path: '/images/realestate/photo8.jpg',
          alt: 'Rooftop garden retreat with lush greenery and panoramic views',
          description: 'Escape to nature with this rooftop garden retreat, surrounded by lush greenery and offering panoramic views of the surroundings.',
        },
        {
          id: 9,
          title: 'Contemporary Exterior Design',
          path: '/images/realestate/photo9.jpg',
          alt: 'Contemporary exterior design showcasing architectural elegance',
          description: 'Experience architectural elegance with this contemporary exterior design, featuring sleek lines and modern elements for a striking facade.',
        },
        {
          id: 10,
          title: 'Tranquil Bedroom Escape',
          path: '/images/realestate/photo10.jpg',
          alt: 'Tranquil bedroom escape with muted tones and cozy furnishings',
          description: 'Unwind in this tranquil bedroom escape, adorned with muted tones and cozy furnishings that create a peaceful and inviting atmosphere.',
        },
        {
          id: 11,
          title: 'Chic Home Bar',
          path: '/images/realestate/photo11.jpg',
          alt: 'Chic home bar with stylish counter and trendy decor',
          description: 'Entertain in style with this chic home bar, featuring a stylish counter and trendy decor that make it a perfect space for socializing.',
        },
        {
          id: 12,
          title: 'Scenic Outdoor Pool',
          path: '/images/realestate/photo12.jpg',
          alt: 'Scenic outdoor pool surrounded by lush landscaping',
          description: 'Enjoy the outdoors with this scenic outdoor pool, surrounded by lush landscaping for a perfect blend of relaxation and natural beauty.',
        },
        {
          id: 13,
          title: 'Modern Entryway Design',
          path: '/images/realestate/photo13.jpg',
          alt: 'Modern entryway design with sleek furniture and artistic accents',
          description: 'Make a stylish entrance with this modern entryway design, featuring sleek furniture and artistic accents that set the tone for the home.',
        },
        {
          id: 14,
          title: 'Spa-Inspired Bathroom',
          path: '/images/realestate/photo14.jpg',
          alt: 'Spa-inspired bathroom with luxurious amenities and calming ambiance',
          description: 'Indulge in luxury with this spa-inspired bathroom, equipped with luxurious amenities and a calming ambiance for a pampering experience.',
        },
        // {
        //   id: 15,
        //   title: 'Outdoor Entertainment Area',
        //   path: '/images/realestate/photo15.jpg',
        //   alt: 'Outdoor entertainment area with cozy seating and festive decor',
        //   description: 'Entertain outdoors with this inviting entertainment area, featuring cozy seating and festive decor that create a perfect space for gatherings.',
        // },
        {
          id: 16,
          title: 'Elegant Staircase Design',
          path: '/images/realestate/photo16.jpg',
          alt: 'Elegant staircase design with modern railing and grandeur',
          description: 'Ascend in style with this elegant staircase design, boasting modern railing and grandeur that elevate the aesthetic of the home.',
        },
        {
          id: 17,
          title: 'Serenity in Nature',
          path: '/images/realestate/photo17.jpg',
          alt: 'Serenity in nature with a home nestled in a lush green landscape',
          description: 'Experience serenity in nature with a home nestled in a lush green landscape, offering a peaceful retreat away from the hustle and bustle.',
        },
        {
          id: 18,
          title: 'Cozy Fireplace Nook',
          path: '/images/realestate/photo18.jpg',
          alt: 'Cozy fireplace nook with comfortable seating and warm glow',
          description: 'Stay warm and cozy in this fireplace nook, featuring comfortable seating and a warm glow that make it an ideal spot for relaxation.',
        },
        {
          id: 19,
          title: 'Modern Minimalist Kitchen',
          path: '/images/realestate/photo19.jpg',
          alt: 'Modern minimalist kitchen with sleek design and efficient layout',
          description: 'Embrace minimalism in this modern kitchen, featuring a sleek design and efficient layout that maximize both style and functionality.',
        },
        {
          id: 20,
          title: 'Picturesque Backyard Retreat',
          path: '/images/realestate/photo20.jpg',
          alt: 'Picturesque backyard retreat with lush greenery and tranquil ambiance',
          description: 'Escape to a picturesque backyard retreat, surrounded by lush greenery and a tranquil ambiance that creates a perfect outdoor haven.',
        },
  ],
  ecommerce: [
   
    ]
};

export default PhotoData;
