import React from 'react'
import ServiceComponent from '../../Components/ServicesComponent/ServiceComponent'

function Services() {
  return (
    <div>  
      <ServiceComponent/>
   </div>
  )
}

export default Services